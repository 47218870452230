import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Formik, Form, Field } from "formik";
import { baseUrl } from '../../../services/config';
import * as Yup from "yup";
// import { Input, TextArea } from "../../../_metronic/_partials/controls";
import { Input } from "./../../././../_metronic/_partials/controls";
import {TextArea} from "./../../././../_metronic/_partials/controls/forms/TextArea"
import firebase, { database } from "firebase";
import { toast } from "react-hot-toast";
import { notificationUrl } from "../../../firebase/config";
import { removeDuplicates } from "../utilts";
// import { serverKey } from "../../../utils/constants";

export default function AddNotificationAlert({
  saveCustomer,
  customer,
  actionsLoading,
  onHide,
  editItem,
  reload,
  UsersList,
  users,
  currentUserData
}) {
  const authtoken = localStorage.getItem("userToken");
  const [loading, setLoading] = useState(false);
  const [image, setImage] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [imageShow, setImageShow] = useState("");
  const [imageEror,setimageError]=useState("") 
  console.log(editItem,"edits")
  const logo = `https://cdn-icons-png.flaticon.com/512/3772/3772243.png`;
  const CustomerEditSchema = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    description: Yup.string().required("Description is required"),
  });
  const editinitialValues = {
    title: editItem.title,
    description: editItem.description,
  };
  const addinitialValues = {
    title: "",
    description: "",

  };
  console.log(imageEror)

  const handleSubmit = (values) => {
    const fdata = new FormData();
fdata.append(`title`,values.title);
fdata.append(`description`,values.description);
if(image){
fdata.append('image',image);
}
const addUrl="admin/notifications"
const editUrl=`admin/notifications/${editItem.id}?_method=PUT`
    setLoading(true)
    fetch(`${baseUrl}/${editItem?editUrl:addUrl}`, {
      method: 'POST',
      
      headers: {
        // 'Accept': 'application/json, text/plain, */*',
        // 'Content-Type': 'application/json',
         Authorization: `Bearer ${authtoken}`,
      },
      body: fdata
    }).then(res => res.json())
      .then((data) => {
        console.log(data)
        setLoading(false)

        if (data.metadata.success) {
          if(editItem){
            toast.success("Updated Successfully")
          }else{
            sendNotificationsToall(values.title,values.description)
            toast.success("Added Successfully")
          }
          setLoading(false)
          reload()
          onHide()
        } else if (!data.metadata.success) {
          Object.values(data.metadata.success).map((msg) => {
            toast.error(msg)
          setLoading(false)
          reload()
          onHide()
          })
        }
      }).catch(err => {
        console.log(err)
        setLoading(false)
        reload()
        onHide()
      })
  }

  const sendNotificationsToall = async (title,description) => {
    UsersList &&
      UsersList.map((user, index) => {
        const pushkey =firebase
        .database()
        .ref("notifications")
        .push().key;
        firebase.database().ref('notifications')
        .child(user.id)
        .child(pushkey).set({
          description: description,
          title: `Admin Added An Announcement ${title}`,
          notificationId:pushkey,
          userName:user.name,
          userId:user.id,
          timeStamp: Date.now(),
        })
        removeDuplicates(user.device_ids).map(async (item, index) => {
          const notification = {
            message: description,
            title: `Admin Added An Announcement ${title}`,
            // sound: "default", // notification sound
          };

          await fetch(notificationUrl, {
            headers: {
              "Content-Type": "application/json",
              // Authorization:
              //   "key=AAAAUq3vR5Y:APA91bHr6pT31qrJsHd0zmUObk7jjhcJreGFwyuOg8Nec0G7-m7gksfm2vtSWjkM0BFOEYq3z2BWdNttqhJrHohufyDlKTUrmeFYab6z0Q1SUjz1T1InF9Ir-r7wXH1gs4KjZzh31EV4",
            },
            method: "POST",
            body: JSON.stringify({
              ...notification,
              deviceToken: item,
            }),
          })
            .then((response) => response.json())
            .then((data) => {
              console.log("response of notifications===>", data);
              if (index + 1 == UsersList.length) {

              }
            })
            .catch((error) => {
              console.log("notification error====>", error);
            });
        });
      });
  };

  // const handleSubmit = (values) => {
  //   if(image==""){
  //     setimageError(true)
  //     return
  //   }
  //   setLoading(true);
  //   const storageRef = firebase.storage().ref();
  //   storageRef
  //     .child(`announcementImages/${image.name}`)
  //     .put(image)
  //     .then(async (snapshot) => {
  //       snapshot.ref.getDownloadURL().then((val) => {
  //           const pushkey = firebase
  //           .database()
  //           .ref("Announcement")
  //           .push().key;
  //           firebase.database().ref('Announcement')
  //           .child(pushkey)
  //           .set({
  //               title:values.title,
  //               description:values.description,
  //               image:val,
  //               id:pushkey
  //           })
  //           .then(() => {
  //             users.map((item,i)=>{
  //               const pushkey = firebase
  //               .database()
  //               .ref("notifications")
  //               .push().key;
  //               firebase.database().ref('notifications')
  //               .child(item.userId)
  //               .child(pushkey)
  //               .set({
  //                 notificationId:pushkey,
  //                 action: 'notification',
  //                 userId: currentUserData.userId,
  //                 message: values.description,
  //                 title:values.title,
  //                 timeStamp: Date.now(),
  //                 userName: currentUserData.userName,
  //                 profileImage: currentUserData.image ? currentUserData.image : '',
  //               })
  //               .then(async () => {
  //                 // showMessage({
  //                 //   message: 'Invitation Send',
  //                 //   duration: 1000,
  //                 //   type: 'success',
  //                 // });
            
  //                 const notification = {
  //                   title: values.title,
  //                   body: values.description,
  //                 };
            
  //                 await fetch('https://fcm.googleapis.com/fcm/send', {
  //                   headers: {
  //                     'Content-Type': 'application/json',
  //                     // Authorization: serverKey
  //                   },
  //                   method: 'POST',
  //                   body: JSON.stringify({
  //                     notification: notification,
  //                     to: item.token,
  //                   }),
  //                 })
  //                   .then(response => response.json())
  //                   .then(data => {
  //                     console.log('response', data);
  //                   });
  //               })
  //               .catch(error => {
  //                 console.log(error)
  //               });
  //              })
  //             setLoading(false);
  //             toast.success('Created Successfuly')
  //             onHide();
  //           })
  //           .catch((err) => console.log(err));
  //       });
  //     })
  //     .catch((err) => {});
  // };


  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={editItem != "" ? editinitialValues : addinitialValues}
        validationSchema={CustomerEditSchema}
        onSubmit={(values) => {
          // editItem ? editHandler(values) :
           handleSubmit(values);
        }}
      >
        {({ values , setFieldValue, isSubmitting }) => (
          <>
            <Form className="form form-label-right">
              <Modal.Body className="overlay overlay-block cursor-default pt-0 pb-0">
                {actionsLoading && (
                  <div className="overlay-layer bg-transparent">
                    <div className="spinner spinner-lg spinner-success" />
                  </div>
                )}
                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Title</label>
                    <Field
                      component={Input}
                      placeholder="Enter title"
                      value={values.title}
                      name="title"
                    />
                  </div>
                </div>

                <div className="form-group row">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <label>Enter Description</label>
                    <Field
                      style={{ height: "8rem" }}
                      value={values.description}
                      name="description"
                       component={TextArea}
                      placeholder="Enter description"
                    />
                  </div>
                </div>

                <div className="form-group row" style={{marginBottom:"0px"}}>
                {imageShow?(
                  <img style={{ height: 150, width: 150 }} src={imageShow} value={image}/>

                   ):
                 editItem.image?
                   <img
                       style={{ height: 150, width: 150 }}
                      src={editItem.image?.original_url}
                  />:
                        null
                        }  
                        
            <label
                    htmlFor="fileImage"
                    style={{
                      width: 100,
                      margin: 20,
                      padding: 10,
                      borderRadius: 5,
                      backgroundColor: "#E6E9EC",
                      cursor: "pointer",
                    }}
                  >
                    <img loading={"lazy"} style={{ width: 78 }} src={logo} />
                  
                  </label>
                
                

                  <input
                    style={{ display: "none" }}
                    type="file"
                    id={`fileImage`}
                    // ref={fileRef}
                    accept="image/png, image/jpeg"
                    onChange={(e) => {
                      setimageError(false)
                      setImageShow(URL.createObjectURL(e.target.files[0]));
                      setImage(e.target.files[0]);
                    }}
                  />
                
                   {/* <lable className="text-danger" name="image">Image is required</lable> */}
                  {/* <input type={'file'} onChange={(e) => setImage(e.target.files[0])} /> */}
                </div>
                {imageEror?
                <lable className="text-danger">Image is required</lable>:
                null  
                }
                  {/* {!image?
                  <lable className="text-danger" name="image">Image is required</lable>:null
                  
                  } */}
              </Modal.Body>
              <Modal.Footer>
                <button
                  type="button"
                  onClick={onHide}
                  className="btn btn-light btn-elevate"
                >
                  Cancel
                </button>
                <> </>
                <button
                  disabled={loading}
                  type="submit"
                  className="btn btn-primary btn-elevate"

                >
                  Save
                </button>
              </Modal.Footer>
            </Form>
          </>
        )}
      </Formik>
    </>
  );
}
